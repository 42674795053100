import React from 'react';
import { Link } from 'react-router-dom';


class NotFoundPage extends React.Component {

    render() {
        return (
            <section>
                <article>
                    <h1>Page not found!</h1>
                    <p>Sorry, but the page you were looking for could not be found. <Link to='/'>Home -&gt;</Link></p>
                </article>
            </section>
        )
    }
}

export default NotFoundPage;