import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LandingPage from './components/LandingPage';
import LipsumPage from './components/Blogs/LipsumPage';
import NotFoundPage from './components/NotFoundPage';


class App extends React.Component {

    render() {
        return(
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route exact path={process.env.PUBLIC_URL + '/'} element={<LandingPage/>} />
                    <Route exact path={process.env.PUBLIC_URL + '/lipsum'} element={<LipsumPage/>} />
                    <Route path='*' element={<NotFoundPage/>} />
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;
