import React from 'react';

import BlogTemplate from '../BlogTemplate';


class LipsumPage extends React.Component {

    render() {
        return (
            <BlogTemplate title='Lipsum' publishedAt='Aug 9, 2022'>
                <p>
                    Fusce faucibus ipsum eros, at viverra lacus elementum nec. Pellentesque mollis, lacus ac maximus aliquam, dolor augue rhoncus nibh, vel venenatis dolor nulla id enim. Praesent sapien arcu, suscipit sed velit sit amet, dapibus maximus neque. Vivamus at ex libero. Aliquam placerat rutrum vulputate. Nunc hendrerit odio leo, a consequat leo tincidunt at. Praesent aliquam, dolor eget dapibus sagittis, augue risus vulputate ante, id tempor elit lectus mattis felis. Suspendisse potenti. Maecenas ac elit at ante viverra dapibus. Donec ipsum dui, vestibulum et nulla non, faucibus congue turpis. In a dolor est. Nunc est ipsum, ornare in diam et, consequat rutrum lacus. Donec at condimentum mauris, vitae maximus lorem. Suspendisse luctus sodales pretium. Quisque molestie ex eget feugiat ultricies.
                    In vitae laoreet leo. Praesent iaculis eu neque ut ullamcorper. Ut tincidunt gravida erat ac volutpat. Sed tempus ante eget urna posuere fermentum. Nulla quis purus nec libero tincidunt dapibus a sit amet massa. Quisque libero.
                </p>
            </BlogTemplate>
        )
    }
}

export default LipsumPage;