import React from 'react';


class BlogTemplate extends React.Component {

    render() {
        return (
            <section>
                <article>
                    <a href='/'>&lt;- Home</a>
                    <h1>{this.props.title}</h1>
                    <h2 className='date'>{this.props.publishedAt}</h2>
                    {this.props.children}
                </article>
            </section>
        )
    }
}

export default BlogTemplate;